import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useListingRedirect } from '~hooks/useListingRedirect';

import { UserContext } from '~contexts/user';
import { AuthContext } from '~contexts/auth';
import FullLoader from '~components/loader/full-loader';

// This page is used in auth0 password reset flow
const Authorize = () => {
  const history = useHistory();

  const { isLoading, isAuthenticated, signIn } = useContext(AuthContext);
  const { user: backendUser, isLoading: isBackendUserLoading, isLoaded: isBackendUserLoaded } = useContext(UserContext);
  const listingRedirect = useListingRedirect();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isAuthenticated) {
      signIn();
      return;
    }

    if(isBackendUserLoaded) {
      history.push(listingRedirect);
    }
  }, [isAuthenticated, isLoading, backendUser, isBackendUserLoading, isBackendUserLoaded]);

  return <FullLoader />;
};

export default Authorize;
